import StakingList from "./StakingList/StakingList";
import StakingStyleWrapper from "./Staking.style";

const Staking = ({ totalStakeBalance, rewardsRate }) => {
  return (
    <StakingStyleWrapper>
      <StakingList />
    </StakingStyleWrapper>
  );
};

export default Staking;
