// Staking Contract
export const contractAddress3 = "0x07E6F2239d6FbE2CE00747fCFb8344ebBf973BcC";

export const contractABI3 = [
  {
    inputs: [
      { internalType: "address", name: "_authority", type: "address" },
      { internalType: "address", name: "_konduxERC20", type: "address" },
      { internalType: "address", name: "_treasury", type: "address" },
      {
        internalType: "address",
        name: "_konduxERC721Founders",
        type: "address",
      },
      { internalType: "address", name: "_konduxERC721kNFT", type: "address" },
      { internalType: "address", name: "_helixERC20", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "contract IAuthority",
        name: "authority",
        type: "address",
      },
    ],
    name: "AuthorityUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "staker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Compound",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewAPR",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      { indexed: true, internalType: "bool", name: "authorized", type: "bool" },
    ],
    name: "NewAuthorizedERC20",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewCompoundFreq",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewDivisorERC20",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewFoundersRewardBoost",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "helixERC20",
        type: "address",
      },
    ],
    name: "NewHelixERC20",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewKNFTRewardBoost",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "konduxERC721Founders",
        type: "address",
      },
    ],
    name: "NewKonduxERC721Founders",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "konduxERC721kNFT",
        type: "address",
      },
    ],
    name: "NewKonduxERC721kNFT",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewMinStake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewRatio",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "treasury",
        type: "address",
      },
    ],
    name: "NewTreasury",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "NewWithdrawalFee",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "netRewards",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fees",
        type: "uint256",
      },
    ],
    name: "Reward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
      {
        indexed: true,
        internalType: "address",
        name: "staker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Stake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "staker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Unstake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "liquidAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fees",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "staker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "WithdrawAll",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "uint256", name: "_apr", type: "uint256" },
      { internalType: "uint256", name: "_compoundFreq", type: "uint256" },
      { internalType: "uint256", name: "_withdrawalFee", type: "uint256" },
      {
        internalType: "uint256",
        name: "_foundersRewardBoost",
        type: "uint256",
      },
      { internalType: "uint256", name: "_kNFTRewardBoost", type: "uint256" },
      { internalType: "uint256", name: "_ratio", type: "uint256" },
      { internalType: "uint256", name: "_minStake", type: "uint256" },
    ],
    name: "addNewStakingToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "allowedDnaVersions",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "aprERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "authority",
    outputs: [
      { internalType: "contract IAuthority", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "authorizedERC20",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_staker", type: "address" },
      { internalType: "uint256", name: "_stakeId", type: "uint256" },
    ],
    name: "calculateBoostPercentage",
    outputs: [
      { internalType: "uint256", name: "boostPercentage", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_staker", type: "address" },
      { internalType: "uint256", name: "_stakeId", type: "uint256" },
    ],
    name: "calculateKNFTBoostPercentage",
    outputs: [
      { internalType: "uint256", name: "boostPercentage", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_staker", type: "address" }],
    name: "calculateMaxKNFTBoostPercentage",
    outputs: [
      { internalType: "uint256", name: "boostPercentage", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_staker", type: "address" },
      { internalType: "uint256", name: "_depositId", type: "uint256" },
    ],
    name: "calculateRewards",
    outputs: [{ internalType: "uint256", name: "rewards", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "claimRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "compoundFreqERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "compoundRewardsTimer",
    outputs: [
      { internalType: "uint256", name: "remainingTime", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "decimalsERC20",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint8", name: "_timelock", type: "uint8" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "deposit",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "divisorERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_depositId", type: "uint256" },
    ],
    name: "earlyUnstake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "earlyWithdrawalPenalty",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "foundersRewardBoostERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_tokenId", type: "address" }],
    name: "getAPR",
    outputs: [
      { internalType: "uint256", name: "_rewardsPerHour", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_dnaVersion", type: "uint256" }],
    name: "getAllowedDnaVersion",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getDecimalsERC20",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_staker", type: "address" },
      { internalType: "uint256", name: "_stakeId", type: "uint256" },
    ],
    name: "getDepositDetails",
    outputs: [
      { internalType: "uint256", name: "_timelock", type: "uint256" },
      { internalType: "uint256", name: "_depositTimestamp", type: "uint256" },
      { internalType: "uint8", name: "_timelockCategory", type: "uint8" },
      { internalType: "uint256", name: "_stake", type: "uint256" },
      { internalType: "uint256", name: "_unclaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "_boostPercentage", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_user", type: "address" }],
    name: "getDepositIds",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getDepositInfo",
    outputs: [
      { internalType: "uint256", name: "_stake", type: "uint256" },
      { internalType: "uint256", name: "_unclaimedRewards", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getDepositRatioERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getDepositTimestamp",
    outputs: [
      { internalType: "uint256", name: "_depositTimestamp", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getDivisorERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "token", type: "address" }],
    name: "getEarlyWithdrawalPenalty",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_tokenId", type: "address" }],
    name: "getFoundersRewardBoost",
    outputs: [
      {
        internalType: "uint256",
        name: "_foundersRewardBoost",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_staker", type: "address" }],
    name: "getMaxTop5BonusesAndIds",
    outputs: [
      { internalType: "uint256[]", name: "top5Bonuses", type: "uint256[]" },
      { internalType: "uint256[]", name: "top5Ids", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_tokenId", type: "address" }],
    name: "getMinStake",
    outputs: [{ internalType: "uint256", name: "_minStake", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getRatioERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getStakedAmount",
    outputs: [{ internalType: "uint256", name: "_deposited", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getTimeOfLastUpdate",
    outputs: [
      { internalType: "uint256", name: "_timeOfLastUpdate", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getTimelock",
    outputs: [{ internalType: "uint256", name: "_timelock", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "getTimelockCategory",
    outputs: [
      { internalType: "uint8", name: "_timelockCategory", type: "uint8" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_category", type: "uint256" }],
    name: "getTimelockCategoryBoost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_staker", type: "address" },
      { internalType: "uint256", name: "_stakeId", type: "uint256" },
    ],
    name: "getTop5BonusesAndIds",
    outputs: [
      { internalType: "uint256[]", name: "top5Bonuses", type: "uint256[]" },
      { internalType: "uint256[]", name: "top5Ids", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getTotalRewards",
    outputs: [
      { internalType: "uint256", name: "_totalRewards", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getTotalStaked",
    outputs: [
      { internalType: "uint256", name: "_totalStaked", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getTotalWithdrawalFees",
    outputs: [
      {
        internalType: "uint256",
        name: "_totalWithdrawalFees",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "getUserTotalRewardsByCoin",
    outputs: [
      { internalType: "uint256", name: "_totalRewards", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "getUserTotalStakedByCoin",
    outputs: [
      { internalType: "uint256", name: "_totalStaked", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_tokenId", type: "address" }],
    name: "getWithdrawalFee",
    outputs: [
      { internalType: "uint256", name: "_withdrawalFee", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_tokenId", type: "address" }],
    name: "getkNFTRewardBoost",
    outputs: [
      { internalType: "uint256", name: "_kNFTRewardBoost", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "helixERC20",
    outputs: [{ internalType: "contract IHelix", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "kNFTRewardBoostERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "konduxERC721Founders",
    outputs: [{ internalType: "contract IERC721", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "konduxERC721kNFT",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "minStakeERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "ratioERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_apr", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setAPR",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_dnaVersion", type: "uint256" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setAllowedDnaVersion",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IAuthority",
        name: "_newAuthority",
        type: "address",
      },
    ],
    name: "setAuthority",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "bool", name: "_authorized", type: "bool" },
    ],
    name: "setAuthorizedERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_compoundFreq", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setCompoundFreq",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint8", name: "_decimals", type: "uint8" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setDecimalsERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_divisor", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setDivisorERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "uint256", name: "penaltyPercentage", type: "uint256" },
    ],
    name: "setEarlyWithdrawalPenalty",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_foundersRewardBoost",
        type: "uint256",
      },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setFoundersRewardBoost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_helix", type: "address" }],
    name: "setHelixERC20",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_konduxERC721Founders",
        type: "address",
      },
    ],
    name: "setKonduxERC721Founders",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_konduxERC721kNFT", type: "address" },
    ],
    name: "setKonduxERC721kNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_minStake", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setMinStake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_ratio", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setRatio",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_category", type: "uint256" },
      { internalType: "uint256", name: "_boost", type: "uint256" },
    ],
    name: "setTimelockCategoryBoost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_treasury", type: "address" }],
    name: "setTreasury",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_withdrawalFee", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setWithdrawalFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_kNFTRewardBoost", type: "uint256" },
      { internalType: "address", name: "_tokenId", type: "address" },
    ],
    name: "setkNFTRewardBoost",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_depositId", type: "uint256" }],
    name: "stakeRewards",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "timelockCategoryBoost",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    name: "timelockDurations",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "totalRewarded",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "totalStaked",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "totalWithdrawalFees",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "treasury",
    outputs: [
      { internalType: "contract ITreasury", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "userDeposits",
    outputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "address", name: "staker", type: "address" },
      { internalType: "uint256", name: "deposited", type: "uint256" },
      { internalType: "uint256", name: "redeemed", type: "uint256" },
      { internalType: "uint256", name: "timeOfLastUpdate", type: "uint256" },
      { internalType: "uint256", name: "lastDepositTime", type: "uint256" },
      { internalType: "uint256", name: "unclaimedRewards", type: "uint256" },
      { internalType: "uint256", name: "timelock", type: "uint256" },
      { internalType: "uint8", name: "timelockCategory", type: "uint8" },
      { internalType: "uint256", name: "ratioERC20", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "userDepositsIds",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userTotalRewardedByCoin",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userTotalStakedByCoin",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_depositId", type: "uint256" },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_depositId", type: "uint256" },
    ],
    name: "withdrawAndClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "withdrawalFeeERC20",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];
