import {
  Heading,
  Stack,
  Container,
  useBreakpointValue,
  useColorModeValue,
  Badge,
  Text,
  Image,
  Center,
} from "@chakra-ui/react";
import Staking from "./Staking/Staking";

import image from "../components/Staking/images/bg/HGLX100FX.png";

import Solid from "../components/Staking/images/logo_shield_text_white-cropped.svg";
// import image from "../components/Staking/images/bg/HLX100.png";
import { Scrollbars } from "react-custom-scrollbars-2";

export function RewardsTab() {
  return (
    <>
      <Scrollbars>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // width: "3840 px",
            // height: "2160 px",
          }}
        >
          <Stack
            // spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="center"
          >
            <Stack spacing="3" paddingTop={8} paddingRight={8} paddingLeft={8}>
              <Badge
                colorScheme="green"
                alignSelf="start"
                size={{ base: "md", md: "lg" }}
                borderRadius={"0px"}
              >
                NEW SITE COMING SOON
              </Badge>

              <Heading
                p={useBreakpointValue({
                  base: "0",
                  lg: "4",
                })}
                size={useBreakpointValue({
                  base: "sm",
                  lg: "lg",
                })}
                fontWeight="bold"
                bgGradient={useBreakpointValue({
                  base: "linear-gradient(270deg, hsl(0deg 0% 100%) 0%, hsl(300deg 100% 100%) 50%, hsl(0deg 0% 100%) 100%)",
                  lg: "linear-gradient(135deg, hsl(28deg 100% 46%) 0%, hsl(26deg 86% 51%) 18%, hsl(26deg 87% 55%) 24%, hsl(25deg 88% 58%) 28%, hsl(25deg 90% 61%) 31%, hsl(24deg 92% 63%) 33%, hsl(24deg 94% 66%) 34%, hsl(24deg 96% 69%) 35%, hsl(24deg 98% 71%) 37%, hsl(23deg 100% 73%) 38%, hsl(24deg 100% 76%) 39%, hsl(24deg 100% 78%) 40%, hsl(24deg 100% 80%) 41%, hsl(24deg 100% 82%) 42%, hsl(24deg 100% 87%) 46%, hsl(24deg 100% 89%) 48%, hsl(24deg 100% 91%) 51%, hsl(24deg 100% 93%) 55%, hsl(24deg 100% 95%) 61%, hsl(24deg 100% 98%) 70%, hsl(0deg 0% 100%) 98%)",
                })}
                bgClip="text"
              >
                Rewards Staking Dashboard
              </Heading>

              <Center>
                <Heading
                  size={useBreakpointValue({
                    base: "xs",
                    lg: "md",
                  })}
                  fontWeight="medium"
                  color="white"
                  pr={2}
                >
                  Audited By:
                </Heading>

                <Image width={"30%"} src={Solid} alt="SolidProof" />
              </Center>

              {/* <Text fontSize={{ base: "xs", md: "sm" }} color="fg.muted">
                Welcome to the next generation of DeFi staking with the Kondux
                Staking System!
                <br></br>
                <br></br>This innovative staking system allows you to maximize
                your earnings by staking your KNDX ERC20 tokens.
              </Text> */}
            </Stack>
          </Stack>

          <Staking />
        </Stack>
      </Scrollbars>
    </>
  );
}
