import React from "react";
import ReactDOM from "react-dom/client";
// import { createRoot } from "react-dom/client";

import App from "./App";
import { mode } from "@chakra-ui/theme-tools";
import { MoralisProvider } from "react-moralis";
import "./index.css";
import {
  ChakraProvider,
  extendTheme,
  theme as baseTheme,
} from "@chakra-ui/react";
import { theme as proTheme } from "@chakra-ui/pro-theme";
import "@fontsource/inter/variable.css";
import { MoralisDappProvider } from "./providers/MoralisDappProvider/MoralisDappProvider";

// import {
//   configureChains,
//   WagmiConfig,
//   createConfig,
// } from "wagmi";

// import { publicProvider } from "wagmi/providers/public";

// import {
//   EthereumClient,
//   w3mConnectors,
//   w3mProvider,
// } from "@web3modal/ethereum";

// import { Web3Modal } from "@web3modal/react";

const projectId = "c738615f90e110ac406a8a1aadca7b64";

// 2. Configure wagmi client
// const chains = [mainnet, sepolia];

// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ version: 1, chains, projectId }),
//   publicClient,
// });

// 3. Configure modal ethereum client
// const ethereumClient = new EthereumClient(wagmiConfig, chains);

const myTheme = extendTheme(
  {
    colors: {
      ...baseTheme.colors,
      purple: {
        50: "#FDE6FE",
        100: "#FABAFD",
        200: "#F68DFB",
        300: "#F361FA",
        400: "#EF35F8",
        500: "#EC08F7",
        600: "#BD07C5",
        700: "#8D0594",
        800: "#5E0363",
        900: "#2F0231",
      },
    },
    config: {
      initialColorMode: "dark",
    },

    fonts: {
      heading: "Poppins, sans-serif",
      body: "Rubik, sans-serif",
    },

    styles: {
      global: (props) => ({
        body: {
          bg: mode("#575866", "#1C1D32")(props),
          icon: mode("#712E6D", "#F37701")(props),
          // color: mode("white", "white")(props),
          // hr: mode("white", "white")(props),
        },
        svg: { color: mode("purple", "#F37701")(props) },
        button: { color: mode("black", "white")(props) },
        // "*, hr": { borderColor: mode("white", "white")(props) },
      }),
    },

    // fonts: {
    //   heading: "Roboto, -apple-system, system-ui, sans-serif",
    //   body: "Roboto, -apple-system, system-ui, sans-serif",
    // },
  },
  proTheme
);

// MAIN WEBSITE
const APP_ID = "4N2HB8tBpvaolPklXTmw5sJZEMqS7rHkHMWXHP6R";
const SERVER_URL = "https://0ipqxknt2fxb.usemoralis.com:2053/server";

// TEST SERVER
// const APP_ID = "TrL1RkBaXhUbfTVmzbvDA0gpKUZF2smvik08vNpF";
// const SERVER_URL = "https://ilzblb7gncqb.grandmoralis.com:2053/server";

const Application = () => {
  return (
    <ChakraProvider theme={myTheme}>
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <MoralisDappProvider>
          {/* <WagmiConfig config={wagmiConfig}> */}
            <App />
          {/* </WagmiConfig> */}
          {/* <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
        </MoralisDappProvider>
      </MoralisProvider>
    </ChakraProvider>
  );
};

// ReactDOM.render(
//   // <React.StrictMode>
//   <Application />,
//   // </React.StrictMode>,
//   document.getElementById("root")
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Application />
  // {/* </React.StrictMode> */}
);
